import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { Link } from "react-router-dom";

import { FaListUl } from "react-icons/fa";
import { FaRegCircleUser } from "react-icons/fa6";
import { GoProjectRoadmap, GoChecklist } from "react-icons/go";
import { IoBusiness } from "react-icons/io5";
import { MdEmail, MdSecurity, MdOutlinePostAdd } from "react-icons/md";
import { BsPinMap } from "react-icons/bs";
import { VscChecklist } from "react-icons/vsc";
import uniteLogo from "/images/unite_logo_no_bg.png";
import uniteDarkLogo from "/images/unite_logo_no_bg_dark.png";
import { fetchApi } from "../../Helpers/Functions";
import { URLs, lastAccessDate } from "../../Constants/Consts";
import { useEffect } from "react";
import { setTaskDisplayedPath } from "../../store/Utilities";
import useOnline from "../../Helpers/CustomHooks/userOnlineCheck";

function Home() {
  const SelectedUser = useSelector(
    (state: RootState) => state.SelectedUser.SelectedUser
  );
  const isOnline = useOnline();

  const isRoleIt = SelectedUser.role === 'IT';
  const isDataMissing = !isRoleIt && !SelectedUser.projectNumber && !SelectedUser.discipline;
  const hasAccess = isRoleIt || !isDataMissing;
  const menuItems = [
    {
      id: 1,
      icon: <VscChecklist size={28} className="mr-4 text-orange-1000" />,
      text: "Checklists",
      path: "/TasksDD",
    },
    {
      id: 1,
      icon: <GoChecklist size={28} className="mr-4 text-orange-1000" />,
      text: "Punch Lists",
      path: "/PunchList",
    },
    {
      id: 1,
      icon: <MdOutlinePostAdd size={28} className="mr-4 text-orange-1000" />,
      text: "Add New Punch List Issue",
      path: `/PunchList/Form/Discipline/1`,
    },
    {
      id: 1,
      icon: <BsPinMap size={28} className="mr-4 text-orange-1000" />,
      text: "Maps / Drawings",
      path: `/DrawingLayouts`,
    },
    {
      id: 2,
      icon: <FaRegCircleUser size={18} className="mr-4 text-orange-1000" />,
      text: "Edit Project Assignment",
      path: `/AllUsers/Edit/${SelectedUser?.uuid}`,
      notAvailAbleOnline: true
    },
  ];

  const filterMenuItems = (id) => {
    return menuItems.filter(item => item.id === id);
  };

  const dispatch = useDispatch()
  useEffect(() => {
    (async () => {
      const lastDateStored = localStorage.getItem(lastAccessDate)
      const lastDate = new Date(lastDateStored).getTime()
      const now = new Date().getTime()
      let differenceInMilliseconds = now - lastDate;
      let differenceInHours = differenceInMilliseconds / 1000 / 60 / 60;
      if (differenceInHours > 1) {
        const storageEstimation = await navigator.storage.estimate()
        const date = new Date(Date.now()).toISOString()
        const logData = {
          userLogUuid: SelectedUser.uuid,
          userEmail: SelectedUser.loginId,
          userAction: "FirstAccess",
          createDatetime: date,
          storageUsageMb: Number(storageEstimation.usage),
          storageQuotaMb: Number(storageEstimation.quota)
        }
        fetchApi(URLs.postUserLogUrl(), {
          method: "POST",
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(logData)
        }).then(() => {
          localStorage.setItem(lastAccessDate, date)
        })
      }
    })()
    return () => {
      // reset task drop down process in case of navigating directly to home page
      dispatch(setTaskDisplayedPath(""))
    }
  }, [SelectedUser])

  return (
    <div className="max-w-8xl">
      <div className="flex justify-center">
        <img
          src={uniteDarkLogo}
          alt="Logo"
          className="max-w-xs md:max-w-sm lg:max-w-md hidden dark:block"
        />
        <img
          src={uniteLogo}
          alt="Logo"
          className="max-w-xs md:max-w-sm lg:max-w-md dark:hidden"
        />
      </div>

      {hasAccess ? (

        <div className="flex justify-center">
          <div className="max-w-screen-lg w-full">
            <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 p-4">

              {!isRoleIt ? (
                <div className="lg:col-span-1 md:col-span-1 col-span-1 flex flex-col p-2">
                  <h2 className="font-bold uppercase">Quick Links:</h2>
                  <div className="space-y-4">
                    <ul className="flex flex-col">
                      {filterMenuItems(1).map(({ icon, text, path }, index) => (
                        <div key={index} className="py-2">
                          <Link to={path} className="flex items-center cursor-pointer">
                            {icon} {text}
                          </Link>
                        </div>
                      ))}
                    </ul>
                  </div>
                </div>
              ) : (
                ""
              )}

              {!isRoleIt ? (

                <div className="lg:col-span-1 md:col-span-1 col-span-1 p-2">
                  <h2 className="font-bold mb-2 uppercase">Project Details:</h2>
                  <div className="space-y-2">
                    <div className="flex items-center">
                      <IoBusiness size={18} className="mr-2" />
                      <span>{SelectedUser.projectNumber} - {SelectedUser.project}</span>
                    </div>
                    <div className="flex items-center">
                      <GoProjectRoadmap size={18} className="mr-2" />
                      <span>{SelectedUser.discipline}</span>
                    </div>

                    <div className="flex items-center flex-wrap">
                      <FaListUl size={18} className="mr-2" />
                      <span>
                        {SelectedUser.sequence ? (
                          SelectedUser.sequence.split(',').reduce((acc, seq, index, array) => {
                            acc.push(<span key={index}>{seq.trim()}</span>);
                            if (index < array.length - 1) {
                              acc.push(', ');
                              if ((index + 1) % 4 === 0 && index < array.length - 1) {
                                acc.push(<br key={`br-${index}`} />);
                              }
                            }
                            return acc;
                          }, [])
                        ) : (
                          <span className="text-gray-400">No Assignment Selected</span>
                        )}
                      </span>
                    </div>

                    <ul className="flex flex-col">
                      {filterMenuItems(2).map(({ icon, text, path, notAvailAbleOnline }, index) => {
                        if (notAvailAbleOnline && !isOnline) {
                          return (
                            <div key={index}>
                              <div className="flex items-center cursor-pointer text-gray-600">
                                {icon} {text}
                              </div>
                            </div>
                          )
                        }
                        return (
                          <div key={index}>
                            <Link to={path} className="flex items-center cursor-pointer">
                              {icon} {text}
                            </Link>
                          </div>
                        )
                      })}
                    </ul>
                  </div>
                </div>

              ) : (
                ""
              )}

              <div className="lg:col-span-1 md:col-span-1 col-span-1 p-2">
                <h2 className="font-bold mb-4 uppercase">User Details:</h2>
                <div className="space-y-2">
                  <div className="flex items-center">
                    <FaRegCircleUser size={18} className="mr-2" />
                    <span>{SelectedUser.fullName}</span>
                  </div>
                  <div className="flex items-center">
                    <MdEmail size={18} className="mr-2" />
                    <span className="truncate">{SelectedUser.kionEmail}</span>
                  </div>
                  <div className="flex items-center text-gray-500">
                    <MdSecurity size={18} className="mr-2" />
                    <span>{SelectedUser.role}</span>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

      ) : (

        <div className="flex flex-col justify-center items-center">
          <p className="text-base mt-5">No Project or Discipline assigned.</p>
          <p className="text-base mt-5">Please create a RemedyForce ticket to be added to UNITE.</p>
          <p className="text-base mt-5">
            ** Provide the{' '}
            <span className="text-orange-700 font-bold">PROJECT</span> number and your{' '}
            <span className="text-orange-700 font-bold">DISCIPLINE</span> in ticket**
          </p>
        </div>

      )}
    </div>

  )
}

export default Home