import { UUID } from "crypto";

export type Page = { href: string, name: string }

export type SequenceNumber = string;

export type SequenceList = SequenceNumber[];

export type AssignmentApiData = {
    "id": number,
    "fullName": string,
    "email": string,
    "projectNumber": number,
    "disciplineId": number,
    "sequence": string,
    "checklistType": string,
    "caCc": string,
    "location": Array<number>,
    "adhocAbility": string,
    "uuid": string,
    "kionEmail": string,
    "loginId": string,
}

export type UserAssignment = {
    "id": number,
    "fullName": string,
    "email": string,
    "role": string,
    "projectNumber": number,
    "disciplineId": number,
    "sequence": string,
    "checklistType": string,
    "caCc": string,
    "location": Array<number>,
    "adhocAbility": string,
    "uuid": string,
    "kionEmail": string,
    "loginId": string,
    project?: string,
    discipline?: string,
    jwt?: string
}
export type AssignmentObject = {
    "id": number,
    "fullName": string,
    "email": string,
    "projectNumber": number,
    "disciplineId": number,
    "sequence": string,
    "checklistType": string,
    "caCc": string,
    "location": string,
    "adhocAbility": string,
    "kionEmail": string,
    "loginId": string,
    "uuid": string,
}

export type Discipline = {
    "id": number,
    "disciplineName": string,
    "disciplineCode": string,
    "drillDownProcess": string,
}

export type Project = {
    "projectNumber": number,
    "projectName": string,
    "projectLocation": string,
    "country": string,
    "isArchived": number
}

export type RelatedChecklistDetails = {
    // "status": number,
    // "projectNumber": number,
    // "disciplineId": number,
    // "sequenceNumber": number,
    // "unitNumber": number,
    // "modelNumber": "string",
    // "modelFull": "string",
    // "inspectionTask": "string",
    // "checklistUuid": "string",

    "ctUuid": string,
    "status": number,
    "projectNumber": number,
    "disciplineId": number,
    "drilldownTypeId": number,
    "dd01": string,
    "dd02": string,
    "dd03": string,
    "ap01": string,
    "bp01": string,
    "taskHeader": string,
    "taskDescription": string,
}

export function getProjectText(project: Project): string {
    return `${project.projectNumber} - ${project.projectName}`;
}

export type UnitChecklistItem = {
    address?: string,
    assignedOn?: Date,
    caCc?: any,
    checklistType?: string,
    checklistUuid?: UUID,
    columnOrder: number,
    dateCreated?: Date,
    dateModified?: Date,
    descriptionDetails?: string,
    deviceInspections?: any,
    projectNumber: number,
    disciplineId: number,
    generalInspections?: any,
    id?: number,
    inspectionId?: string,
    inspectionTask: string,
    inspectionTestType?: string,
    inspectionVersion?: Date,
    isArchived?: number,
    location?: any,
    modelFull: string,
    modelNumber: string,
    plcAddress?: any,
    qrCode?: any
    sequenceName?: string
    sequenceNumber: string
    status?: number,
    unitNumber: string,
    twClassNames?: string,
    passedIcon_twClassNames?: string,
    failedIcon_twClassNames?: string,
    NAIcon_twClassNames?: string,
    notSync?: boolean,
    mpcStatus?: number,
    creation?: boolean
}

export type CACC = {
    "status": number,
    "projectNumber": number,
    "disciplineId": number,
    "checklistType": string,
    "caCc": string,
    "location": string,
    "deviceInspections": string,
    "descriptionDetails": string,
    "plcAddress": string,
    "address": string,
    "inspectionTestType": string,
    "inspectionTask": string,
    "generalInspections": string, // check again
    "qrCode": string, // check again
    // "checklistControlUuid": string,
    "columnOrder": string, // check again
    "dateCreated": string,
    "dateModified": string,
    "inspectionVersion": string, // check again
    "isArchived": number
}

export type PunchListItem = {
    ermType?: string;
}

export type Comment = {
    "commentUuid": string,
    "commentType": number,
    "projectNumber": string,
    "comment1": string,
    "addedBy": string,
    "addedEmail": string,
    "addedOn": string,
    "unitChecklistUuid": string,
    "punchlistUuid": string,
    "disciplineId": string,
    unitChecklistId?: string,
    punchlistId?: string,
    "id"?: number,
    notSync?: boolean
    creation?: boolean
}

export type ChangeLog = {
    "id": number,
    "changelogType": number,
    "projectNumber": string,
    "disciplineId": string,
    "punchListId": string;
    "punchlistUuid": string;
    "unitChecklistId": string | null,
    "unitChecklistUuid": string;
    "data": string,
    "changedBy": string,
    "changedEmail": string,
    "changedOn": string,
    "changelogUuid": string,
}
export type ChangeLogType = {
    "id": number,
    "typeName": string,
    "description": string
}
export type Status = {
    "statusId": number,
    "statusName": string,
    "statusDescription": string
}
export type Issue = {
    "issueId": number,
    "issueText": string,
    "mechanicalPc": string,
    "electricalPc": string,
    "mechanicalInstall": string,
    "electricalInstall": string,
    "controlEngineering": string,
    "mechanicalEngineering": string,
    "qaSafety": string,
    "siteManager": string,
}

export type Category = {
    "categoryId": number,
    "categoryName": string,
    "mechanicalPc": string,
    "electricalPc": string,
    "mechanicalInstall": string,
    "electricalInstall": string,
    "controlEngineering": string,
    "mechanicalEngineering": string,
    "qaSafety": string,
    "site_manager": string,
}


export type ObjectToSync = {
    url: string,
    payload: any
    method: string,
    type: string,
    preRequests?: Array<ObjectToSync>
    jwt?: string
}

export type DrawingType = {
    "id": number,
    "drawingId": string,
    "drawingName": string,
    "projectNumber": number
}

export type MPCStatus = {
    meChecklistUuid: string
    mpcChecklistUuid: string
    mpcStatus: number
    projectNumber: number
    sequenceNumber: number
    unitNumber: number
}